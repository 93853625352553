
import WMSHome from "./home";
import WMSHeader from "./header";
import WMSPicking from "./picking";


const path = document.body.getAttribute("data-current-path")
if (path && path.indexOf('wms') !== -1) {
    new WMSHeader()
}
if (path && path === "wms/home") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "index") {
        new WMSHome();
    }
}
if (path && path === "wms/picking") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "show") {
        new WMSPicking();
    }
}
